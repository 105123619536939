import { Table } from 'antd'
import { t } from 'i18next'
import React, { useContext, useEffect } from 'react'
import AdminContext from '../../AdminContext'
import { displayDateAndHour } from '../../common/date'
import AdminChat from '../../Chat/AdminChat'
import UserStats from './UserStats'
import { USE_CHAT } from '../../common/constants'

export default function UserList () {
  const { users, getLastMessage } = useContext(AdminContext)

  useEffect(() => {
    getLastMessage()
  }, [])

  const columns = [
    {
      title: t('User.attributes.email'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('User.attributes.last_message'),
      dataIndex: 'last_message',
      key: 'lat_message',
      render: (text, record) => {
        return record.last_message ? record.last_message.content : ''
      }
    },
    {
      title: t('User.attributes.author'),
      dataIndex: 'author',
      key: 'author',
      render: (_, user) => {
        if (user.last_message) {
          return users.find((elt) => (elt.id === user.last_message.user_id)).email
        }
      }
    },
    {
      title: t('User.attributes.last_message_creation'),
      dataIndex: 'last_message_creation',
      key: 'last_message_creation',
      sorter: (a, b) => {
        return a.last_message?.created_at - b.last_message?.created_at
      },
      render: (_, user) => {
        if (user.last_message) {
          return displayDateAndHour(user.last_message.created_at)
        }
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, user) => <div style={{ display: 'inline-flex' }}>
        {USE_CHAT && <AdminChat user={user}/>}
        <UserStats user={user} />
      </div>
    }
  ]
  return (
    <>
      {users.length > 0 &&
        <Table columns={columns} dataSource={users} style={{ marginTop: '2em' }} rowKey="id" />
      }
    </>
  )
}
