import { Col, Form, Row, DatePicker, Select, Card, Button, Tooltip, Input } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import React, { useEffect } from 'react'
import { displayDate } from '../common/date'
import { FileExcelOutlined } from '@ant-design/icons'
import { exportExcel } from '../common/api'
import { stringNormalize } from '../common/string'
const { RangePicker } = DatePicker

const dueDateOptions = () => {
  const result = [
    { label: 'today', value: dayjs() },
    { label: 'tomorrow', value: dayjs().add(1, 'day') },
    { label: 'this_week', value: dayjs().endOf('week') },
    { label: 'next_week', value: dayjs().add(1, 'week').endOf('week') },
    { label: 'this_month', value: dayjs().endOf('month') },
    { label: 'next_month', value: dayjs().add(1, 'month').endOf('month') },
    { label: 'this_year', value: dayjs().endOf('year') }
  ].map((option) => {
    return {
      label: t(`Task.filter.due_date.${option.label}`),
      value: displayDate(option.value)
    }
  })
  return result.concat([
    { label: t('Task.filter.due_date.overdue'), value: 'overdue' },
    { label: t('Task.filter.due_date.all'), value: 'all' }
  ])
}

export default function TaskFilter ({ setSelectedTasks, tasks, selectedReport }) {
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      period: [dayjs().subtract(1, 'year'), dayjs()],
      due_date: 'all',
      search: ''
    })
    updateSelectedTasks()
  }, [tasks])

  const updateSelectedTasks = () => {
    const [startDate, endDate] = form.getFieldValue('period') || [dayjs().subtract(20, 'year'), dayjs()]
    const filteredTasks = tasks.filter(task => {
      return (task.filter_date >= startDate) && (task.filter_date <= endDate)
    }).filter(task => {
      const dueDate = form.getFieldValue('due_date')
      if (dueDate === 'overdue') { return task.overdue }
      if (dueDate === 'all') { return task }
      return (task.due_date && task.due_date.isBefore(dayjs(dueDate, 'DD/MM/YYYY')) &&
        task.due_date.isAfter(dayjs()))
    }).filter(task => {
      const search = stringNormalize(form.getFieldValue('search'))
      return (search === '') || task.search_data.includes(search)
    })

    setSelectedTasks(filteredTasks)
  }

  const exportFile = () => {
    let url = '/tasks/export'
    if (selectedReport) {
      url += `?accident_report_id=${selectedReport.id}`
    }
    exportExcel(url)
  }

  return (
    <Card style={{ marginTop: '20px', padding: '10px 0 0 10px' }}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{t('Task.list_title')}</span>
          <Tooltip title={t('Task.export')}>
            <Button type='link' icon={<FileExcelOutlined />} size="large" onClick={() => exportFile()} />
          </Tooltip>
        </div>
      }>
      <Form layout="vertical" form={form}>
        <Row>
          <Col span={4}>
            <Form.Item name='period' label={t('Task.filter.occurrence_date')} >
              <RangePicker onChange={updateSelectedTasks} format={'DD/MM/YYYY'} placeholder='jj/mm/aaaa' />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <Form.Item name='due_date' label={t('Task.filter.due_date.label')} >
              <Select options={dueDateOptions()} onChange={updateSelectedTasks} />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={6}>
            <Form.Item name='search' label={t('Task.filter.search')} >
              <Input placeholder="" onChange={updateSelectedTasks} />
            </Form.Item>
          </Col>
        </Row>
      </Form >
    </Card>
  )
};
