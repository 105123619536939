import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Menu, Tour } from 'antd'
import { Color } from '../common/Color'
import UserContext from '../UserContext'
import Step from './Step'

const homepageItem = {
  label: (
    <Link to="/" style={{ width: '200px' }}>
      <div>
        <img src="/assets/logo-ws-analytics-H.svg" alt="Logo ws analytics" style={{ padding: '15px 0', verticalAlign: 'middle' }} />
      </div>
    </Link>
  ),
  key: 'logo'
}

export default function MobileNavigation () {
  const location = useLocation()
  const [current, setCurrent] = useState(location.pathname.slice(1).split('/')[1] ?? '')
  const { user } = useContext(UserContext)

  const [open, setOpen] = useState(false)

  const items = [homepageItem]

  const connectedClass = (user?.id) ? 'connected' : 'not-connected'

  const buttons = {
    nextButtonProps: { children: '>>', style: { fontWeight: 'bold', width: '80px', height: '50px' } },
    prevButtonProps: { children: '<<', style: { fontWeight: 'bold', width: '80px', height: '50px' } }
  }

  const steps = [
    {
      title: 'Dashboard',
      description: 'Visualisez les indicateurs clés. Vous avez 2 exemples qui ont été créés',
      cover: (
        <img
          alt="Visualisation des indicateurs"
          src="/assets/dashboard.png"
        />
      ),
      ...buttons
    },
    {
      title: 'Événements',
      description: 'Visualisez vos événements. Créez 10 événements en mode gratuit',
      cover: (
        <img
          alt="Liste des évènements"
          src="/assets/reports.png"
        />
      ),
      ...buttons
    },
    {
      title: 'Création d\'événement',
      description: 'Rédigez librement vos événements.',
      cover: (
        <img
          alt="Création d'événement"
          src="/assets/report_creation.png"
        />
      ),
      ...buttons
    },
    {
      title: 'Ishikawa',
      description: 'Générez grace à l\'IA WS un diagramme d\'Ishikawa et les actions associées aux différentes causes. Le résultat est modifiable.',
      cover: (
        <img
          alt="Diagramme d'Ishikawa"
          src="/assets/ishikawa.png"
        />
      ),
      ...buttons
    },
    {
      title: 'Actions',
      description: 'Visualisez l\'avancement de vos actions par évènement ou globalement',
      cover: (
        <img
          alt="Liste des actions"
          src="/assets/tasks.png"
        />
      ),
      ...buttons
    },
    {
      title: 'RoadMap',
      description: 'Visualisez la roadmap sur 12 mois glissant',
      cover: (
        <img
          alt="Roadmap"
          src="/assets/roadmap.png"
        />
      ),
      ...buttons
    },
    {
      title: 'Abonnez-vous',
      description: 'Abonnez-vous afin de suivre l\'ensemble de vos évènements et bénéficiez des exports',
      ...buttons
    },
    {
      title: 'Connectez-vous',
      description: 'L\'application est disponible uniquement en mode desktop pour le moment.',
      ...buttons
    }
  ]

  return (
    <>
      <Menu className={connectedClass} style={{ display: 'flex', fontSize: '18px', borderRadius: '16px', backgroundColor: Color.white, color: Color.dark_blue }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
        mode='horizontal'
        items={items} />
      <Step />
      <Button style={{ marginTop: '2em', width: '100%' }} type="primary" className="icon-btn tour-btn" onClick={() => setOpen(true)}>
        Présentation de l&lsquo;application
      </Button>
      <Tour open={open} onClose={() => setOpen(false)} steps={steps} style={{ marginTop: '2em', width: 'auto' }} />
    </>)
}
