import { Spin } from 'antd'
import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { get, post } from './common/api'

const UserContext = createContext()
export default UserContext

export function UserContextProvider ({ children }) {
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [user, setUser] = useState({ is_paid: false })
  const [stripeInfos, setStripeInfos] = useState({})
  const [subscriptionInfos, setSubscriptionInfos] = useState({})

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(
      '/user_infos', {},
      (successResponse) => {
        setUser(successResponse.user)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const getStripeInfos = async () => {
    if (!user.is_paid) { return }
    setSpinData({ ...spinData, loading: true })
    await get(
      '/stripe_infos', {},
      (successResponse) => {
        setStripeInfos(successResponse.customer)
        const subscription = successResponse.customer.subscriptions.data[0]
        setSubscriptionInfos(subscription)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const cancelSubscription = async () => {
    setSpinData({ ...spinData, loading: true })
    await post(
      '/payments/cancel_subscription', {},
      (successResponse) => {
        setUser(successResponse.user)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const values = {
    getUser,
    user,
    getStripeInfos,
    stripeInfos,
    subscriptionInfos,
    setSpinData,
    cancelSubscription
  }

  return (
    <UserContext.Provider value={values}>
      <Spin spinning={spinData.loading} tip={spinData.tip} size="large">
        {children || <Outlet />}
      </Spin>
    </UserContext.Provider>
  )
}
