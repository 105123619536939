import { Button, Popconfirm } from 'antd'

import { BulbOutlined } from '@ant-design/icons'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { t } from 'i18next'
import { get } from '../../common/api'
import Chart from './Chart'
import AccidentReportContext from '../AccidentReportContext'
import UserContext from '../../UserContext'
import AccidentReportModal from '../AccidentReportModal'

export default function Ishikawa ({ report, tasks }) {
  const { reportId } = useParams()
  const {
    ishikawa, setIshikawa, fillIshikawa, generateTasks,
    setSpinData, getAccidentReport
  } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)

  const onSuccess = (res) => {
    setIshikawa(res.ishikawa)
  }

  useEffect(() => {
    const id = report?.id || reportId
    get(`/accident_reports/${id}/ishikawa`, {}, onSuccess)
  }, [reportId])

  useEffect(() => {
    if (report && ['queued', 'in_progress'].indexOf(report.ishikawa_generation) >= 0) {
      setSpinData({ loading: true, tip: 'Génération en cours' })
      setTimeout(() => { getAccidentReport(report?.id || reportId) }, 1000)
    }
  }, [report])

  useEffect(() => {
    if (report && ['queued', 'in_progress'].indexOf(report.tasks_generation) >= 0) {
      setSpinData({ loading: true, tip: 'Génération en cours' })
      setTimeout(() => { getAccidentReport(report?.id || reportId) }, 1000)
    }
  }, [report])

  const generateIshikawa = () => {
    fillIshikawa(reportId)
  }

  const generateIATasks = () => {
    generateTasks(reportId)
  }

  const disabled = !user.is_paid && report.free_data

  const filled = () => (ishikawa?.defined)

  return (
    <>
      <AccidentReportModal report={report} />
      {filled() &&
        <Popconfirm
          title={t('Ishikawa.regenerate.title')}
          description={t('Ishikawa.regenerate.description')}
          onConfirm={generateIshikawa}
          okText={t('Actions.yes')}
          cancelText={t('Actions.no')}
        >
          <Button
            className="icon-btn"
            icon={<BulbOutlined />}
            style={{ margin: '0 2em 1em 0' }}
            size="large"
            disabled={disabled}
            danger>
            {t('Ishikawa.regenerate_by_IA')}
          </Button>
        </Popconfirm>
      }
      {!filled() &&
        <Button
          className="icon-btn"
          icon={<BulbOutlined />}
          style={{ margin: '0 2em 1em 0' }}
          size="large"
          onClick={generateIshikawa}
        >
          {t('Ishikawa.generate_by_IA')}
        </Button>
      }
      {filled() && tasks.length > 0 &&
        <Popconfirm
          title={t('Task.deleteIA.title')}
          description={t('Task.deleteIA.description')}
          onConfirm={generateIATasks}
          okText={t('Actions.yes')}
          cancelText={t('Actions.no')}
        >
          <Button
            className="icon-btn"
            icon={<BulbOutlined />}
            style={{ margin: '0 2em 1em 0' }}
            size="large"
            disabled={disabled}
            danger>
            {t('Task.regenerate_by_IA')}
          </Button>
        </Popconfirm>
      }
      {filled() && tasks.length === 0 &&
        <Button
          className="icon-btn"
          icon={<BulbOutlined />}
          style={{ margin: '0 2em 1em 0' }}
          size="large"
          onClick={generateIATasks}
          disabled={disabled}
        >
          {t('Task.generate_by_IA')}
        </Button>
      }
      {ishikawa && <Chart ishikawa={ishikawa} report={report} tasks={tasks} />}
    </>
  )
}
