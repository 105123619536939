import Layout from 'antd/es/layout/layout'
import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserHistory } from 'history'
import { ConfigProvider } from 'antd'
import frFR from 'antd/locale/fr_FR'
import 'dayjs/locale/fr'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import dayjs from 'dayjs'
import i18next from 'i18next'
import HomePage from './HomePage/HomePage'
import { Color } from './common/Color'
import Ishikawa from './AccidentReport/Ishikawa/Ishikawa'
import AccidentReportList from './AccidentReport/AccidentReportList'
import { AccidentReportContextProvider } from './AccidentReport/AccidentReportContext'
import { UserContextProvider } from './UserContext'
/* eslint-disable no-unused-vars */ // keep the following line
import i18n from './locales/i18n'
import AccidentReport from './AccidentReport/AccidentReport'
import TaskList from './Task/TaskList'
import PapripactDashboard from './Papripact/PapripactDashboard'
import PapripactCalendar from './Papripact/PapripactCalendar'
import Profile from './Profile/Profile'
import Navigation from './Navigation/Navigation'
import { AdminContextProvider } from './AdminContext'
import UserList from './Admin/Users/UserList'
/* eslint-enable no-unused-vars */

dayjs.locale('fr')
i18next.init({
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b']
  }
})
const history = createBrowserHistory()
function App ({ _arg }) {
  return (
    <Layout>
      <Router history={history}>
        <Routes>
          <Route element={<UserContextProvider />}>
            <Route element={<Navigation />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/profile" element={<Profile />} />
              <Route element={<AccidentReportContextProvider />}>
                <Route path="/dashboard" element={<PapripactDashboard />} />
                <Route path="reports">
                  <Route path="" element={<AccidentReportList />} />
                  <Route path=":reportId" element={<AccidentReport />}>
                    <Route path="ishikawa" element={<Ishikawa />} />
                  </Route>
                </Route>
                <Route path="actions" element={<TaskList />} />
                <Route path='calendar' element={<PapripactCalendar />} />
              </Route>
              <Route element={<AdminContextProvider />}>
                <Route path="/admin">
                  <Route path="users" element={<UserList />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </Layout >
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.getElementById('root')
  const root = ReactDOM.createRoot(rootEl)
  root.render(
    <React.StrictMode>
      <ConfigProvider
        locale={frFR}
        theme={{
          token: {
            fontFamily: 'custom-font',
            colorPrimary: Color.primary,
            borderRadius: '10px',
            colorInfo: '#D3A800'
          }
        }}
      >
        <App />
      </ConfigProvider>
    </React.StrictMode>
  )
})
