import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import consumer from '../cable'
import { get } from '../common/api'
import { FileTextOutlined } from '@ant-design/icons'
import Messages from './Messages'
import { formatMessage } from '../common/chat'

export default function AdminChat ({ user }) {
  const [isOpen, setIsOpen] = useState(false)
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [messages, setMessages] = useState([])

  useEffect(() => {
    if (isOpen) {
      componentDidMount()
      getMessages()
    } else {
      componentWillUnmount()
    }
  }, [isOpen])

  const addMessage = (currentMessages) => {
    const formattedMessages = currentMessages.map(formatMessage)
    messages.push(...formattedMessages)
    setMessages([...messages])
  }

  const componentDidMount = () => {
    consumer.subscriptions.create({
      channel: 'SupportChatChannel',
      id: user.id
    }, {
      connected: () => { },
      disconnected: () => { setMessages([]) },
      received: (data) => { if (data.message) { addMessage([data.message]) } }
    })
  }

  const componentWillUnmount = () => { consumer.disconnect() }

  const getMessages = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(`/admin/users/${user.id}/support_chat`, {},
      (successResponse) => { addMessage(successResponse.messages) }
    )
    setSpinData({ loading: false, tip: '' })
  }

  return (<>
    <Button onClick={() => setIsOpen(!isOpen)} style={{ zIndex: '200' }}
      type='link' icon={<FileTextOutlined />} />

    <Modal className='chat'
      open={isOpen}
      title="Le Chat"
      onCancel={() => setIsOpen(false)}
      footer={null}
      width="50%"
    >
      <Messages messages={messages} sendUrl={`/admin/users/${user.id}/support_chat/messages`} />
    </Modal >
  </>)
}
