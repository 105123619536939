import React, { useContext, useEffect, useState } from 'react'
import { Card, Col, Row } from 'antd'
import { Pie, Column } from '@ant-design/plots'
import { t } from 'i18next'
import { CATEGORIES } from '../common/constants'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'

const PROGRESS_BLOCS = ['not_started', 0, 1, 2, 3, 4]
const removeEmpty = (array) => {
  return array.filter((item) => item.nombre !== 0)
}

export default function PapripactDashboard () {
  const { getTasks, tasks, reports, getAccidentReports } = useContext(AccidentReportContext)
  const [taskGraph, setTaskGraph] = useState([])

  useEffect(() => {
    getTasks()
    getAccidentReports()
  }, [])

  const computeTaskGraph = () => {
    const counters = {}
    tasks.forEach(task => {
      const key = task.category
      counters[key] = (counters[key] || 0) + 1
    })
    const newTaskGraph = CATEGORIES.map((category) => {
      return {
        type: t('Ishikawa.attributes.' + category),
        nombre: counters[category] || 0
      }
    })
    return removeEmpty(newTaskGraph)
  }

  const computeReportGraph = () => {
    const counters = {
      empty_ishikawa: 0,
      ishikawa_defined: 0,
      tasks_defined: 0,
      tasks_in_progress: 0,
      tasks_done: 0
    }
    reports.forEach(report => {
      if (!report.ishikawa.defined) {
        counters.empty_ishikawa++
      } else if (report.tasks_count === 0) {
        counters.ishikawa_defined++
      } else {
        if (report.tasks_progress === 0) {
          counters.tasks_defined++
        } else if (report.tasks_progress < 100) {
          counters.tasks_in_progress++
        } else if (report.tasks_progress === 100) {
          counters.tasks_done++
        }
      }
    })
    return [
      { type: t('Dashboard.report.empty_ishikawa'), nombre: counters.empty_ishikawa, color: 'red' },
      { type: t('Dashboard.report.ishikawa_defined'), nombre: counters.ishikawa_defined, color: 'blue' },
      { type: t('Dashboard.report.tasks_defined'), nombre: counters.tasks_defined, color: 'green' },
      { type: t('Dashboard.report.tasks_in_progress'), nombre: counters.tasks_in_progress, color: 'yellow' },
      { type: t('Dashboard.report.tasks_done'), nombre: counters.tasks_done, color: 'purple' }
    ]
  }

  const computeTaskProgress = () => {
    const counters = {}
    tasks.forEach(task => {
      const key = (task.progress === 0) ? 'not_started' : parseInt(task.progress / 25)
      counters[key] = (counters[key] || 0) + 1
    })
    return PROGRESS_BLOCS.map((bloc) => {
      return {
        type: t('Dashboard.task_progress.' + bloc),
        nombre: counters[bloc] || 0
      }
    })
  }

  useEffect(() => {
    setTaskGraph(computeTaskGraph())
  }, [tasks])

  const config = {
    angleField: 'nombre',
    colorField: 'type',
    paddingRight: 80,
    label: {
      text: 'type',
      position: 'inside'
    },
    legend: {
      maxRows: 10,
      color: {
        title: true,
        position: 'bottom',
        rowPadding: 1
      }
    }
  }

  const barConfig = {
    xField: 'type',
    yField: 'nombre',
    colorField: 'type'
  }

  const reportData = computeReportGraph()

  const taskProgressData = computeTaskProgress()

  return (
    <Card style={{ marginTop: '20px', backgroundColor: '#f6f6f6' }} title=''>
      <Row style={{ height: '20%' }}>
        <Col span={6} style={{ padding: '0 20px' }}>
          <Card title={t('Dashboard.report.title')} bordered={false}>
            <Column {...barConfig} data={reportData} />
          </Card>
        </Col>
        <Col span={6} style={{ padding: '0 20px' }}>
          <Card title={t('Dashboard.task_progress.title')} bordered={false}>
            <Column {...barConfig} data={taskProgressData} />
          </Card>
        </Col>
        <Col span={6} style={{ padding: '0 20px' }}>
          <Card title={t('Dashboard.task.category_graph', { count: tasks.length })} bordered={false}>
            {taskGraph.length > 0 &&
              <Pie {...config} data={taskGraph} />
            }
          </Card>
        </Col>
      </Row>
    </Card >
  )
}
