{
	"AccidentReport": {
		"add": "Nouveau rapport d'accident / incident",
		"attributes": {
			"description": "Description",
			"occurrence_date": "Date de l'événement",
			"progress": "Avancement",
			"title": "Titre"
		},
		"button": {
			"edit": "Modifier",
			"export": "Export Excel de l'événement"
		},
		"delete": {
			"title": "Suppression de l'évènement",
			"description": "Êtes-vous sûr de vouloir supprimer l'événement ainsi que le diagramme et les actions associées ?"
		},
		"form": {
			"required": "Ce champ est obligatoire"
		},
		"placeholder": {
			"description": "Ceci est une description de l'accident",
			"title": "Éclatement de la conduite principale"
		},
		"progress": {
			"empty_ishikawa": "Aucune analyse",
			"ishikawa_defined": "Analyse effectuée",
			"tasks_defined_one": "1 Tache définie - non commencée",
			"tasks_defined_other": "{{count}} Taches définies - non commencées",
			"tasks_in_progress": "{{count}} Taches en cours - {{tasks_progress}} %",
			"tasks_done": "{{count}} Taches terminées"
		},
		"message": {
			"add_disabled": "Vous ne pouvez pas ajouter un nouveau rapport d'accident / incident avec un compte gratuit",
			"edit_disabled": "Vous ne pouvez pas modifier ce rapport d'accident / incident avec un compte gratuit"
		},
		"modal": {
			"buttonText": "Visualisation le rapport"
		}
	},
	"Actions": {
		"add": "Ajouter",
		"cancel": "Annuler",
		"no": "Non",
		"validate": "Enregistrer",
		"yes": "Oui",
		"save": "Enregistrer",
		"update": "Enregistrer"
	},
	"Dashboard": {
		"report": {
			"empty_ishikawa": "Aucune analyse",
			"ishikawa_defined": "Analyse effectuée",
			"tasks_defined": "Taches définies",
			"tasks_in_progress": "Taches en cours",
			"tasks_done": "Événement clos",
			"title": "Analyse des évènements"
		},
		"task": {
			"category_graph_zero": "Tâches par cause - 0 tâche",
			"category_graph_one": "Tâches par cause - 0 tâche",
			"category_graph_other": "Tâches par cause - {{count}} tâches"
		},
		"task_progress": {
			"not_started": "Non commencée",
			"0": "1% - 25%",
			"1": "25% - 50%",
			"2": "50% - 75%",
			"3": "75% - 99%",
			"4": "Terminée",
			"title": "Avancement des tâches"
		}
	},
	"Ishikawa": {
		"attributes": {
			"associatedTasks_zero": "Aucune action associée",
			"associatedTasks_one": "1 action associée",
			"associatedTasks_other": "{{count}} actions associées",
			"manpower": "Main d'oeuvre",
			"machine": "Machine",
			"material": "Matière",
			"method": "Méthode",
			"measurement": "Milieu",
			"title": "Titre"
		},
		"generate_by_IA": "Générer le diagramme à l'aide de l'IA",
		"regenerate_by_IA": "RÉGÉNÉRER le diagramme à l'aide de l'IA",
		"regenerate": {
			"title": "Nouvelle génération",
			"description": "Êtes-vous sûr d'effacer le diagramme courant ?"
		}
	},
	"Mois": {
		"1": "Janv.",
		"2": "Fév.",
		"3": "Mars",
		"4": "Avr.",
		"5": "Mai",
		"6": "Juin",
		"7": "Juill.",
		"8": "Août",
		"9": "Sept.",
		"10": "Oct.",
		"11": "Nov.",
		"12": "Déc."
	},
	"Task": {
		"attributes": {
			"accident_report": "Titre évènement",
			"category": "Catégorie",
			"created_at": "Créé le",
			"description": "Description",
			"due_date": "Date limite",
			"leader": "Responsable",
			"occurrence_date": "Date de l'événement",
			"progress": "Progression en %",
			"selected": "Sélectionné dans la roadmap",
			"title": "Titre"
		},
		"button": {
			"edit": "Modifier",
			"validate": "Action terminée"
		},
		"category": {
			"manpower": "main-d'oeuvre",
			"machine": "machine",
			"material": "matières",
			"method": "méthode",
			"measurement": "milieu"
		},
		"delete": {
			"title": "Suppression de l'action",
			"description": "Êtes-vous sûr de vouloir supprimer l'actions suivante ?"
		},
		"deleteIA": {
			"title": "Suppression des actions",
			"description": "Êtes-vous sûr de vouloir supprimer les précédentes actions générée par l'IA ?"
		},
		"export": "Export Excel des tâches",
		"filter": {
			"due_date": {
				"label": "Échéance",
				"today": "Aujourd'hui",
				"tomorrow": "Demain",
				"this_week": "Cette semaine",
				"next_week": "Semaine prochaine",
				"this_month": "Ce mois-ci",
				"next_month": "Mois prochain",
				"this_year": "Cette année",
				"overdue": "En retard",
				"all": "-"
			},
			"occurrence_date": "Date de l'événement",
			"search": "Recherche"
		},
		"form": {
			"required": "Ce champ est obligatoire"
		},
		"generate_by_IA": "Générer les actions avec l'IA",
		"list_title": "Liste des actions",
		"selectedTasks": {
			"progress": "Avancement des tâches sélectionnées : {{progress}}%"
		},
		"title_zero": "Aucune action",
		"title_one": "1 action",
		"title_other": "{{count}} actions",
		"regenerate_by_IA": "RÉGÉNÉRER les actions avec l'IA"
	},
	"TaskListModal": {
		"title": "Taches associés"
	},
	"User": {
		"attributes": {
			"author": "auteur",
			"email": "email",
			"last_message": "dernier message",
			"last_message_creation": "date du message"
		},
		"account": {
			"free": "Compte gratuit",
			"paid": "Compte payant",
			"title": "Détails de l'abonnement",
			"created_at": "Crée le",
			"current_period_label": "Période actuelle",
			"current_period": "du {{startDate}} au {{endDate}}"
		},
		"subscription": {
			"cancel": "Arrêter l'abonnement",
			"title": "Arrêt de l'abonnement",
			"description": "Êtes-vous sûr de vouloir arrêter votre abonnement ?"
		}
	},
	"UserStats": {
		"label": {
			"created_at": "date de création du compte",
			"last_sign_in_at": "dernière connexion",
			"accident_report_count": "nombre d'évènements",
			"ishikawa_count": "nombre d'analyses",
			"task_count": "nombre d'actions",
			"task_done_count": "nombre d'actions en cours",
			"task_in_progress_count": "nombre d'actions en cours",
			"sign_in_count": "nombre de connexion"
		}
	}
}