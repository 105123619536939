import React from 'react'
import { Row } from 'antd'

export default function SecurityMessage () {
  return (
    <Row style={{ padding: '30px 0' }}>
      <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
        Sécurité de vos données :
      </span>
      <br />
      <span>
        Vos données sont stockées en France (sur un cloud souverain) et ne sont pas partagées avec des tiers.
        <br />
        L&apos;infrastructure de notre d&apos;IA est aussi déployée en France (sur un cloud souverain).
        <br />
        Les données saisies par les utilisateurs de compte gratuit sont automatiquement supprimées après une durée de 2 mois sans connexion.
      </span>
    </Row>
  )
}
