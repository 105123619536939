import React, { useContext, useEffect, useState } from 'react'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'
import TaskFilter from './TaskFilter'
import { columns } from './columns'
import CustomTable from '../../CustomTable'
import { t } from 'i18next'
import UserContext from '../UserContext'
import TaskCreate from './TaskCreate'
import { sumBy } from 'lodash'

export default function TaskList ({ selectedReport }) {
  const { tasks, reports, getTasks, getAccidentReports, validateTask, deleteTask } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const [selectedTasks, setSelectedTasks] = useState([])
  const [progress, setProgress] = useState(0)
  const [allTasks, setAllTasks] = useState([])

  useEffect(() => { getAccidentReports() }, [])

  useEffect(() => { getTasks() }, [reports])

  useEffect(() => {
    const array = selectedReport?.tasks || tasks
    if (array.length > 0) {
      setSelectedTasks(array)
      setAllTasks(array)
    }
  }, [tasks, selectedReport])

  useEffect(() => {
    if (selectedTasks.length === 0) {
      setProgress('-')
    } else {
      const total = sumBy(selectedTasks, 'progress')
      setProgress(parseInt(total / selectedTasks.length))
    }
  })

  const validate = (task) => {
    validateTask(task, task.accident_report_id)
  }
  const deleteCurrentTask = (task) => {
    deleteTask(task.id, task.accident_report_id)
  }
  return (
    <>
      <div style={{ marginTop: '1em', textAlign: 'right' }}>
        <TaskCreate report={selectedReport} withCategory />
      </div>
      <TaskFilter tasks={allTasks} setSelectedTasks={setSelectedTasks} selectedReport={selectedReport} />
      <div style={{ padding: '10px', fontWeight: 'bold' }}>
        {t('Task.title', { count: selectedTasks.length })} - {t('Task.selectedTasks.progress', { progress })}
      </div>
      <CustomTable
        initColumns={columns(reports, validate, user, deleteCurrentTask, selectedReport)}
        dataSource={selectedTasks}
        rowKey={(task) => (task?.id)}
      />
    </>
  )
};
