import React, { useContext, useState } from 'react'
import { Button, Form, Input, List, Spin } from 'antd'
import UserContext from '../UserContext'
import { post } from '../common/api'
import { Color } from '../common/Color'
import { displayDateAndHour } from '../common/date'

export default function Messages ({ messages, sendUrl }) {
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [form] = Form.useForm()
  const { user } = useContext(UserContext)

  const sendMessage = async (values) => {
    setSpinData({ loading: true, tip: 'Envoi en cours' })
    await post(sendUrl, { message: { content: values.message } },
      (_) => {
        form.resetFields()
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const isAdmin = (id) => (id !== user.id)

  const justifyContent = (id) => (isAdmin(id) ? 'end' : 'start')

  return (
    <Spin spinning={spinData.loading} tip={spinData.tip} size="large">
      <List
        itemLayout="horizontal"
        dataSource={messages}
        style={{ height: '360px', overflow: 'auto', flexDirection: 'column-reverse', display: 'flex' }}
        renderItem={message => (
          <List.Item style={{ justifyContent: justifyContent(message.user_id), display: 'flex' }}>
            <div style={{ display: 'block', justifyContent: justifyContent(message.user_id) }}>
              <div>{message.content}</div>
              <div style={{ color: Color.dark_grey, fontSize: '0.5em', display: 'block' }}>{displayDateAndHour(message.created_at)}</div>
            </div>
          </List.Item>
        )
        }
      />
      < Form layout="vertical" name="chatForm" onFinish={sendMessage} form={form} >
        <Form.Item name="message">
          <Input placeholder="Votre message" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form.Item>
      </Form >
    </Spin >)
}
