import React from 'react'
import { List, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'

export default function Step () {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const minWidth = isMobile ? '300px' : '400px'

  let firstStep = false
  if (isMobile) {
    firstStep = ' l\'application est disponible uniquement en mode desktop pour le moment'
  }
  const data = [
    { title: 'Bienvenue sur WS-ANALYTICS', description: 'Voici les 5 étapes que vous devez suivre pour utiliser WS-Analytics :', newLine: true },
    { title: '1', description: 'Créez un compte gratuit pour accéder à l\'IA', extra: firstStep },
    { title: '2', description: 'Saisissez un rapport d\'accident / presque accident / incident ...' },
    { title: '3', description: 'Observez l\'IA effectuer une analyse et créer des tâches associées' },
    { title: '4', description: 'Régénérez, Modifiez, Ajoutez et Acceptez l\'analyse et les tâches' },
    { title: '5', description: 'Votre outil de pilotage est prêt !', style: { textTransform: 'uppercase' } }
  ]
  return (

    <Row style={{ paddingTop: '15px' }}>
      <List
        style={{ margin: 'auto', minWidth }}
        itemLayout="vertical"
        dataSource={data}
        size="small"
        renderItem={(item) => (
          <List.Item style={item.style}>
            <span style={{ fontWeight: 'bold', marginRight: '1em' }}>{item.title}</span>
            {item.newLine && <br />}
            <span>{item.description}</span>
            {item.extra && <> <br /><span style={{ fontSize: '0.8em' }}>{item.extra}</span></>}
          </List.Item>
        )}
      />
    </Row>
  )
}
