import React, { useContext } from 'react'
import { Button, DatePicker, Divider, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { SaveOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import AccidentReportContext from './AccidentReportContext'
import UserContext from '../UserContext'

export default function AccidentReportEdit ({ report }) {
  const { createOrUpdateAccidentReport } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const [form] = Form.useForm()
  form.setFieldsValue(report)

  const handleOk = () => {
    createOrUpdateAccidentReport(form.getFieldsValue(), report.id)
  }

  const inputStyle = {
    width: '100%'
  }

  const formItemStyle = {
    padding: '0 15%',
    fontWeight: 'bold'
  }

  const disabled = !user.is_paid && report.free_data

  return (
    <>
      {report &&
        <>
          <Form id='accidentForm' layout='vertical' form={form}>
            <Divider orientation="center">Événement</Divider>
            <Form.Item required name="title" label={t('AccidentReport.attributes.title')} style={formItemStyle}>
              <Input style={inputStyle} placeholder={t('AccidentReport.placeholder.title')} />
            </Form.Item>
            <Form.Item required name="occurrence_date" label={t('AccidentReport.attributes.occurrence_date')} style={formItemStyle}>
              <DatePicker format={'DD/MM/YYYY'} placeholder='jj/mm/aaaa' />
            </Form.Item>
            <Form.Item required name="description" label={t('AccidentReport.attributes.description')} style={formItemStyle}>
              <TextArea style={inputStyle} placeholder={t('AccidentReport.placeholder.description')} rows={10} />
            </Form.Item>
          </Form >

          <Button className='icon-btn' icon={<SaveOutlined />} onClick={handleOk} disabled={disabled} size='large'>
            {t('Actions.update')}
          </Button>
        </>
      }
    </>
  )
}
