import React, { useContext, useEffect, useState } from 'react'
import { Card, FloatButton } from 'antd'
import consumer from '../cable'
import UserContext from '../UserContext'
import { get } from '../common/api'
import Messages from './Messages'
import { formatMessage } from '../common/chat'

export default function Chat () {
  const [isOpen, setIsOpen] = useState(false)
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [messages, setMessages] = useState([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (isOpen) {
      componentDidMount()
      getMessages()
    } else {
      componentWillUnmount()
    }
  }, [isOpen])

  const addMessage = (currentMessages) => {
    const formattedMessages = currentMessages.map(formatMessage)
    messages.push(...formattedMessages)
    setMessages([...messages])
  }

  const componentDidMount = () => {
    consumer.subscriptions.create({
      channel: 'SupportChatChannel',
      id: user.id
    }, {
      connected: () => { },
      disconnected: () => { setMessages([]) },
      received: (data) => { if (data.message) { addMessage([data.message]) } }
    })
  }

  const componentWillUnmount = () => { consumer.disconnect() }

  const getMessages = async () => {
    setSpinData({ ...spinData, loading: true })
    await get('/support_chat', {},
      (successResponse) => { addMessage(successResponse.messages) }
    )
    setSpinData({ loading: false, tip: '' })
  }

  return (<>
    <FloatButton onClick={() => setIsOpen(!isOpen)} style={{ zIndex: '200' }} />
    <Card className='chat' hidden={!isOpen}
      style={{ position: 'fixed', top: '1em', right: '1em', width: '35em', height: '550px', zIndex: '100' }}
      title="Le Chat"
    >
      <Messages messages={messages} sendUrl='/support_chat/messages' />
    </Card >
  </>)
}
