import { Alert, Tabs } from 'antd'

import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AccidentReportContext from './AccidentReportContext'
import Ishikawa from './Ishikawa/Ishikawa'
import AccidentReportEdit from './AccidentReportEdit'
import UserContext from '../UserContext'
import TaskList from '../Task/TaskList'
import { t } from 'i18next'

export default function AccidentReport () {
  const { reportId } = useParams()
  const { user } = useContext(UserContext)
  const [disabled, setDisabled] = useState(false)
  const { report, getAccidentReport } = useContext(AccidentReportContext)

  useEffect(() => {
    getAccidentReport(reportId)
  }, [])

  useEffect(() => {
    if (report && user) {
      setDisabled(!user.is_paid && report.free_data)
    }
  }, [report])

  const items = [
    {
      key: '1',
      label: 'Détails',
      children: <AccidentReportEdit report={report} />
    },
    {
      key: '2',
      label: 'Ishikawa',
      children: <Ishikawa report={report} tasks={report?.tasks} />
    },
    {
      key: '3',
      label: 'Actions',
      children: <TaskList selectedReport={report} />
    }
  ]

  return (
    report &&
    <div style={{ marginTop: '1em', marginBottom: '1em', height: '90vh' }}>
      {disabled &&
        <Alert style={{ marginBottom: '10px' }} message={t('AccidentReport.message.edit_disabled')} type="error" />
      }
      <Tabs className="tab" defaultActiveKey="1" items={items}
      />
    </div>
  )
}
