import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Modal } from 'antd'
import { LineChartOutlined } from '@ant-design/icons'
import { get } from '../../common/api'
import { t } from 'i18next'
import { displayDate } from '../../common/date'
import dayjs from 'dayjs'

export default function UserStats ({ user }) {
  const [isOpen, setIsOpen] = useState(false)
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [stats, setStats] = useState([])

  const formatStats = (stats) => {
    const result = []
    const dateKeys = ['created_at', 'last_sign_in_at']
    for (const key in stats) {
      result.push({
        key,
        label: t(`UserStats.label.${key}`),
        children: dateKeys.indexOf(key) >= 0 ? displayDate(dayjs(stats[key])) : stats[key]
      })
    }
    return result
  }

  useEffect(() => {
    if (isOpen) {
      getUserStats()
    }
  }, [isOpen])

  const getUserStats = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(`/admin/users/${user.id}/stats`, {},
      (successResponse) => { setStats(formatStats(successResponse.stats)) }
    )
    setSpinData({ loading: false, tip: '' })
  }

  return (<>
    <Button onClick={() => setIsOpen(!isOpen)} style={{ zIndex: '200' }}
      type='link' icon={<LineChartOutlined />} />

    <Modal className='stat'
      open={isOpen}
      title={`Statistique de ${user.email}`}
      onCancel={() => setIsOpen(false)}
      footer={null}
      width="50%"
    >
      <Descriptions
        size='default'
        items={stats}
      />
    </Modal >
  </>)
}
