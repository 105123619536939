import { t } from 'i18next'
import React, { useContext, useEffect } from 'react'
import { Card, Col, Row, Tag, Tooltip } from 'antd'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'
import TaskEdit from '../Task/TaskEdit'
import { CATEGORIES, COLORS } from '../common/constants'

const Months = () => {
  const months = []
  for (let i = 1; i < 13; i++) {
    months.push(
      <Col span={2} style={{ padding: '0 20px' }}>
        <h2>{t(`Mois.${((i + 4) % 12) + 1}`)}</h2>
      </Col>
    )
  }
  return months
}

const CategoryTasks = ({ category, tasks }) => {
  const result = []
  if (!tasks) return result

  tasks.forEach((task) => {
    result.push(
      <Row style={{ padding: '2px 0 ' }}>
        <Col span={task.start}></Col>
        <Col span={task.duration}>
          <Tooltip placement="topRight" title={task.period}>
            <Tag color={COLORS[category]} style={{ whiteSpace: 'initial', width: '100%' }}>
              <TaskEdit task={task} accidentReportId={task.accident_report_id}
                textButton={task.title} />
            </Tag>
          </Tooltip>
        </Col>
      </Row >
    )
  })
  return result
}

const Tasks = ({ tasks }) => {
  const result = []
  let color = '#fff'
  CATEGORIES.forEach(category => {
    color = (color === '#fff') ? '#f0f0f0' : '#fff'
    const categoryTasks = <CategoryTasks category={category} tasks={tasks[category]} />
    result.push(
      <Row style={{ padding: '10px', backgroundColor: color }}>
        <Col span={4}>{t('Ishikawa.attributes.' + category)}</Col>
        <Col span={20}>
          {categoryTasks}
        </Col>
      </Row>
    )
  })
  return result
}

export default function PapripactCalendar () {
  const { getCalendar, calendar } = useContext(AccidentReportContext)

  useEffect(() => {
    getCalendar()
  }, [])

  return (
    <Card style={{ marginTop: '20px' }} title='Roadmap - 01/06/2024 - 31/05/2025'>

      <Row>
        <Col span={4} style={{ padding: '0 20px' }}>
        </Col>
        <Col span={20} style={{ padding: '0 20px' }}>
          <Row>
            <Months />
          </Row>
        </Col>
      </Row>
      <Tasks tasks={calendar} />
    </Card>
  )
}
