export const Color = {
  red: '#fa4c4f',
  green: '#23b091',
  light_green: '#adedc8',
  primary_blue: '#40a9ff',
  dark_blue: '#243746',
  light_blue: '#bddae5',
  dark_orange: '#fab038',
  orange: '#fcd79c',
  light_orange: '#fef3e1',
  white: '#fff',
  black: '#000',
  grey: '#E0E0E0',
  dark_grey: '#333333',
  primary: '#D3A800'
}
