import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'
import React, { useEffect } from 'react'

const formItemStyle = {
  padding: '0 15%',
  fontWeight: 'bold'
}
const inputStyle = {
  width: '100%'
}

export default function TaskForm ({ form, category, withCategory, onFinish, handleCancel }) {
  const categories = [
    { value: 'manpower', label: "main-d'oeuvre" },
    { value: 'machine', label: 'machine' },
    { value: 'material', label: 'matières' },
    { value: 'method', label: 'méthode' },
    { value: 'measurement', label: 'milieu' }]

  useEffect(() => {
    if (!category) return
    form.setFieldsValue({ category })
  }, [category])

  return (
    <Form
      id="taskForm"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
    >
      <Form.Item hidden name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Task.form.required') }]}
        name="title"
        label={t('Task.attributes.title')}
        style={formItemStyle}
      >
        <Input style={inputStyle} placeholder="Titre de la tache" />
      </Form.Item>

      <Form.Item
        rules={[{ required: true, message: t('Task.form.required') }]}
        name="description"
        label={t('Task.attributes.description')}
        style={formItemStyle}
      >
        <TextArea style={inputStyle} placeholder="Ceci est une description de la tache" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: t('Task.form.required') }]}
        name="leader"
        label={t('Task.attributes.leader')}
        style={formItemStyle}
      >
        <Input style={inputStyle} placeholder="Responsable de la tache" />
      </Form.Item>
      <Form.Item
        name='due_date' label={t('Task.attributes.due_date')} style={formItemStyle}
        rules={[{ required: true, message: t('Task.form.required') }]}
      >
        <DatePicker format={'DD/MM/YYYY'} placeholder='jj/mm/aaaa' />
      </Form.Item>
      {!withCategory &&
        <Form.Item hidden name="category">
          <Input type="hidden" />
        </Form.Item>
      }
      {withCategory &&
        <Form.Item name="category" label={t('Task.attributes.category')} style={formItemStyle}
          rules={[{ required: true, message: t('Task.form.required') }]}
        >
          <Select options={categories} />
        </Form.Item>
      }
      <Form.Item
        name="progress"
        label={t('Task.attributes.progress')}
        style={formItemStyle}
      >
        <InputNumber style={inputStyle} max="100" min="0" defaultValue='0' />
      </Form.Item>
      <Form.Item
        name="selected"
        style={formItemStyle}
        valuePropName="checked"
      >
        <Checkbox>{t('Task.attributes.selected')}</Checkbox>
      </Form.Item>
      <Form.Item style={formItemStyle}>
        <Space>
          <Button type="primary" htmlType="submit">
            {t('Actions.save')}
          </Button>
          <Button htmlType="button" onClick={handleCancel}>
            {t('Actions.cancel')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
