import { EditOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import TaskForm from './TaskForm'
import dayjs from 'dayjs'
import { t } from 'i18next'
import UserContext from '../UserContext'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'

export default function TaskEdit ({ accidentReportId, withCategory = false, task, textButton }) {
  const { updateTask } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (!task) return
    updateForm(task)
  }, [task])

  const updateForm = (task) => {
    form.setFieldsValue({ ...task, due_date: task.due_date && dayjs(task.due_date) })
  }

  const onFinish = async () => {
    await updateTask(form.getFieldValue(), accidentReportId)
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
    updateForm(task)
  }

  const disabled = !user.is_paid && task.free_data

  return (
    <>
      {textButton &&
        <span onClick={() => setIsModalOpen(true)} style={{ cursor: 'pointer' }} disabled={disabled}>
          {textButton}
        </span>
      }
      {!textButton &&
        <Tooltip title={t('Task.button.edit')}>
          <Button type="link" icon={<EditOutlined />}
            onClick={openModal}
            disabled={disabled} />
        </Tooltip>
      }
      <Modal
        title="Modifier la tache"
        open={isModalOpen}
        footer={[]}
        onCancel={handleCancel}
        width="800px"
      >
        <TaskForm smallTask form={form} withCategory={withCategory}
          withProgress={true} onFinish={onFinish} handleCancel={handleCancel}/>
      </Modal>
    </>
  )
}
