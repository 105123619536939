import React from 'react'
import { t } from 'i18next'
import { Button, Popconfirm, Progress } from 'antd'
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons'
import { displayDate } from '../common/date'
import TaskEdit from '../Task/TaskEdit'

export const columns = (reports, validate, user, deleteCurrentTask, selectedReport) => {
  const result = [
    {
      title: t('Task.attributes.occurrence_date'),
      dataIndex: 'filter_date',
      key: 'filter_date',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.filter_date - b.filter_date,
      render: (d) => { return displayDate(d) }
    },
    {
      title: t('Task.attributes.accident_report'),
      dataIndex: 'accident_report_title',
      key: 'accident_report_title',
      render: (title, task) => {
        const recordId = task.accident_report_id
        return (<a href={`/reports/${recordId}`}>{title}</a>)
      },
      sorter: (a, b) => (a.accident_report_title.localeCompare(b.accident_report_title))
    },
    {
      title: t('Task.attributes.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: t('Task.attributes.due_date'),
      dataIndex: 'due_date',
      key: 'due_date',
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => a.due_date - b.due_date,
      render: displayDate
    },
    {
      title: t('Task.attributes.leader'),
      dataIndex: 'leader',
      key: 'leader',
      sorter: (a, b) => a.leader?.localeCompare(b.leader)
    },
    {
      title: t('Task.attributes.selected'),
      dataIndex: 'selected',
      key: 'selected',
      render: (selected) => {
        return (selected && <CheckOutlined />)
      }
    },
    {
      title: t('Task.attributes.progress'),
      dataIndex: 'progress',
      key: 'progress',
      sorter: (a, b) => (a - b),
      render: (progress) => (
        <Progress type="circle" percent={progress || 0} size={20} />
      )
    },
    {
      title: t('Task.attributes.category'),
      dataIndex: 'category',
      key: 'category',
      render: (category) => t(`Task.category.${category}`),
      sorter: (a, b) => a.category.localeCompare(b.category)
    },
    {
      title: '',
      dataIndex: '',
      key: 'validate',
      render: (_, task) => {
        // const disabled = !user.is_paid && task.free_data
        return (
          < div style={{ display: 'inline-flex' }}>
            <Popconfirm
              title={t('Task.delete.title')}
              description={t('Task.delete.description')}
              onConfirm={() => deleteCurrentTask(task)}
              okText={t('Actions.yes')}
              cancelText={t('Actions.no')}
            >
              <Button type='link'
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
            {/* <Button type='link'
              icon={<CheckOutlined />}
              onClick={() => validate(task)}
              disabled={disabled}
            /> */}
            <TaskEdit task={task} withCategory accidentReportId={selectedReport?.id} />
          </div>
        )
      }
    }
  ]
  return result
}
