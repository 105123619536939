import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form, Modal } from 'antd'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import TaskForm from './TaskForm'
import UserContext from '../UserContext'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'

export default function TaskCreate ({ report, smallTask = false, category, withCategory = false }) {
  const { createTask } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => {
    setIsModalOpen(true)
    form.setFieldsValue({ id: report?.id, progress: 0 })
  }

  const onFinish = async () => {
    const id = report?.id
    await createTask(form.getFieldValue(), id)
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const disabled = !user.is_paid && report?.free_data

  return (
    <>
      {!smallTask &&
        (
          <Button className="icon-btn" icon={<PlusOutlined />}
            onClick={showModal} size="large"
            disabled={disabled}>
            {t('Actions.add')}
          </Button>
        )}
      {smallTask &&
        <Button type="link" icon={<PlusCircleOutlined />} onClick={showModal} disabled={disabled} />}
      <Modal
        title="Ajouter une tache"
        open={isModalOpen}
        footer={[]}
        onCancel={handleCancel}
        width="800px"
      >
        <TaskForm category={category} form={form} withCategory={withCategory} onFinish={onFinish} handleCancel={handleCancel} />
      </Modal>
    </>
  )
}
