import { Button, ConfigProvider, DatePicker, Divider, Form, Input, Modal, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import AccidentReportContext from './AccidentReportContext'

export default function AccidentReportForm ({ addDisabled }) {
  const { createOrUpdateAccidentReport } = useContext(AccidentReportContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()

  const showModal = () => { setIsModalOpen(true) }

  const onFinish = async () => {
    await createOrUpdateAccidentReport(form.getFieldsValue())
    form.resetFields()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  const inputStyle = { width: '100%' }

  const formItemStyle = {
    padding: '0 15%',
    fontWeight: 'bold'
  }

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              titleFontSize: '20px'
            }
          }
        }}
      >
        <Modal
          className="accident"
          title={t('AccidentReport.add')}
          open={isModalOpen}
          footer={[]}
          onCancel={handleCancel}
          width="50%"
          style={{ top: 10 }}
        >
          <Form id="accidentForm" layout="vertical" form={form} onFinish={onFinish}>
            <Divider orientation="center">Événement</Divider>
            <Form.Item name="title" label={t('AccidentReport.attributes.title')} style={formItemStyle}
              rules={[{ required: true, message: t('AccidentReport.form.required') }]}>
              <Input style={inputStyle} placeholder={t('AccidentReport.placeholder.title')} />
            </Form.Item>
            <Form.Item required name="occurrence_date" label={t('AccidentReport.attributes.occurrence_date')} style={formItemStyle}
              rules={[{ required: true, message: t('AccidentReport.form.required') }]}>
              <DatePicker format={'DD/MM/YYYY'} placeholder='jj/mm/aaaa' />
            </Form.Item>
            <Form.Item required name="description" label={t('AccidentReport.attributes.description')} style={formItemStyle}
              rules={[{ required: true, message: t('AccidentReport.form.required') }]}>
              <TextArea style={inputStyle} placeholder={t('AccidentReport.placeholder.description')} rows={10} />
            </Form.Item>
            <Form.Item style={formItemStyle}>
              <Space>
                <Button type="primary" htmlType="submit">
                  {t('Actions.save')}
                </Button>
                <Button htmlType="button" onClick={handleCancel}>
                  {t('Actions.cancel')}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
      <Button className="icon-btn" icon={<PlusOutlined />} onClick={showModal} size="large" disabled={addDisabled}>
        {t('Actions.add')}
      </Button>
    </>
  )
}
