import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { ConfigProvider, Layout } from 'antd'
import { useMediaQuery } from 'react-responsive'
import MobileNavigation from './MobileNavigation'
import DesktopNavigation from './DesktopNavigation'
import Chat from '../Chat/Chat'
import { USE_CHAT } from '../common/constants'
import UserContext from '../UserContext'

export default function Navigation ({ children }) {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
  const { user } = useContext(UserContext)

  return (
    <Layout>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              activeBarHeight: 0,
              horizontalItemSelectedBg: '#D3A800',
              horizontalItemSelectedColor: 'white',
              itemSelectedBg: '#D3A800',
              itemSelectedColor: 'white',
              itemHoverBg: 'white',
              subMenuItemBorderRadius: 0
            }
          }
        }}
      >
        {isMobile ? <MobileNavigation /> : <DesktopNavigation />}

      </ConfigProvider>
      {children || <Outlet />}
      {USE_CHAT && !isMobile && user.admin && <Chat />}
    </Layout>
  )
}
