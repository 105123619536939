import { Alert, Button, Popconfirm, Table, Tooltip } from 'antd'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { EditOutlined, FileExcelOutlined, DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import AccidentReportContext from './AccidentReportContext'
import AccidentReportForm from './AccidentReportForm'
import UserContext from '../UserContext'
import { MAX_REPORTS_FOR_FREE_USER } from '../common/constants'
import { exportExcel } from '../common/api'
import { displayDate } from '../common/date'

export default function AccidentReportList () {
  const { reports, getAccidentReports, deleteAccidentReport } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const [addDisabled, setAddDisabled] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getAccidentReports()
  }, [])

  useEffect(() => {
    setAddDisabled(!user.is_paid && reports.length > MAX_REPORTS_FOR_FREE_USER)
  }, [user, reports])

  const edit = (report) => { navigate(`/reports/${report.id}`) }

  const exportFile = (report) => { exportExcel(`/accident_reports/${report.id}/export`) }

  const exportDisabled = (report) => !user.is_paid && !report.free_data
  const deleteDisabled = (report) => !user.is_paid && report.free_data

  const columns = [
    {
      title: t('AccidentReport.attributes.occurrence_date'),
      dataIndex: 'occurrence_date',
      key: 'occurrence_date',
      sorter: (a, b) => a.occurrence_date - b.occurrence_date,
      render: displayDate
    },
    {
      title: t('AccidentReport.attributes.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title)
    },
    {
      title: t('AccidentReport.attributes.progress'),
      dataIndex: 'progress',
      key: 'progress',
      render: (progress, report) => t('AccidentReport.progress.' + progress, { tasks_progress: report.tasks_progress, count: report.tasks_count }),
      sorter: (a, b) => {
        return (a.tasks_progress - b.tasks_progress)
      }
    },
    {
      title: t('AccidentReport.attributes.description'),
      dataIndex: 'description',
      key: 'description',
      render: (description) => <span style={{ whiteSpace: 'pre-line' }}>{description}</span>
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, report) => <div style={{ display: 'inline-flex' }}>
        <Popconfirm
          title={t('AccidentReport.delete.title')}
          description={t('AccidentReport.delete.description')}
          onConfirm={() => deleteAccidentReport(report.id)}
          okText={t('Actions.yes')}
          cancelText={t('Actions.no')}
        >
          <Button className="ant-btn-lg" type='link' icon={<DeleteOutlined />} disabled={deleteDisabled(report)} />
        </Popconfirm>
        <Tooltip title={t('AccidentReport.button.edit')}>
          <Button type='link' icon={<EditOutlined />} size="large" onClick={() => edit(report)} />
        </Tooltip>
        <Tooltip title={t('AccidentReport.button.export')}>
          <Button type='link' icon={<FileExcelOutlined />} size="large" disabled={exportDisabled(report)} onClick={() => exportFile(report)} />
        </Tooltip>
      </div>
    }
  ]
  return (
    <>
      {addDisabled &&
        <Alert style={{ top: '10px' }} message={t('AccidentReport.message.add_disabled')} type="error" />
      }
      <div style={{ marginTop: '1em', textAlign: 'right' }}>
        <AccidentReportForm addDisabled={addDisabled} />
      </div>
      <Table columns={columns} dataSource={reports} style={{ marginTop: '2em' }} rowKey="id" />
    </>
  )
}
