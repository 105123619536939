import { notification } from 'antd'

export const tokenCSRF = () => document.querySelector('meta[name="csrf-token"]').content

const defaultOnFailure = (response, onFailure, _override) => {
  if (onFailure) {
    onFailure(response)
    return
  }
  notification.error({ message: Object.values(response).join(' '), placement: 'bottom' })
}

const afterRequest = async (response, onSuccess, onFailure, override, isUserInfo) => {
  if (response.status === 401) {
    if (isUserInfo) {
      return { user: 'not_connected' }
    } else {
      window.location.href = '/users/sign_in'
      return
    }
  }
  let data = {}
  const resolved = (response.status === 200 || response.status === 204)
  if (response.status !== 204) {
    data = await response.json()
  }

  if (resolved) {
    onSuccess(data)
  } else {
    defaultOnFailure(data, onFailure, override)
  }
}

const header = () => {
  const h = new Headers()
  h.append('Cache-Control', 'no-cache')
  h.append('Accept', 'application/json')
  h.append('Accept-Encoding', 'gzip,deflate')
  h.append('Connection', 'keep-alive')
  return h
}

const jsonHeader = () => {
  const h = header()
  h.append('X-CSRF-Token', tokenCSRF())
  h.append('Content-Type', 'application/json;charset=UTF-8;')
  return h
}

const multipartHeader = () => {
  const h = header()
  h.append('X-CSRF-Token', tokenCSRF())
  // The following line is commented because it's not working with
  // h.append('Content-Type', 'multipart/form-data')
  return h
}

export const apiUrl = (url) => encodeURI(`/api/v1${url}`)

export const post = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: jsonHeader(),
    body: JSON.stringify(params),
    signal: params.signal,
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const postMultipart = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: multipartHeader(),
    body: formDataMultipart(params),
    signal: params.signal,
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const postFile = async (url, formData, onSuccess, onFailure = null, { override } = { override: false }) => {
  const h = new Headers()
  h.append('Cache-Control', 'no-cache')
  h.append('Accept', '*/*')
  h.append('Accept-Encoding', 'gzip,deflate')
  h.append('Connection', 'keep-alive')

  const requestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: h,
    body: formData,
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)
  if (response.status === 200) {
    onSuccess(response)
  } else {
    defaultOnFailure(response.json(), onFailure, override)
  }
}

const formDataMultipart = (params) => {
  const formData = new FormData()
  for (const name in params) {
    if (params[name]) {
      formData.append(name, params[name])
    }
  }
  return formData
}

export const putMultipart = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'PUT',
    headers: multipartHeader(),
    body: formDataMultipart(params),
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const put = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'PUT',
    headers: jsonHeader(),
    body: JSON.stringify(params),
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const deleteRequest = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'DELETE',
    headers: jsonHeader(),
    body: JSON.stringify(params),
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const logOut = async () => {
  const requestOptions = {
    credentials: 'include',
    method: 'DELETE',
    headers: jsonHeader(),
    redirect: 'follow'
  }

  const response = await fetch('/users/sign_out', requestOptions)
  if (response.status === 204) {
    window.location.href = '/users/sign_in'
  }
}

export const deleteRequestForm = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'DELETE',
    headers: header(),
    body: params,
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)

  await afterRequest(response, onSuccess, onFailure, override)
}

export const get = async (url, params, onSuccess, onFailure = null, { override } = { override: false }) => {
  const requestOptions = {
    credentials: 'include',
    method: 'GET',
    headers: jsonHeader(),
    redirect: 'follow'
  }

  const isUserInfo = url === '/user_infos'
  url = `${url}?${Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')}`
  const response = await fetch(apiUrl(url), requestOptions)
  await afterRequest(response, onSuccess, onFailure, override, isUserInfo)
}

const storeFile = (blob, contentType, fileName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'
  const newBlob = blob.slice(0, blob.size, contentType)
  const objectURL = URL.createObjectURL(newBlob)
  a.href = objectURL
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(objectURL)
}

export const exportExcel = async (url, onSuccess = () => { }, onFailure = null) => {
  const requestOptions = {
    credentials: 'include',
    method: 'GET',
    headers: jsonHeader(),
    redirect: 'follow'
  }

  const response = await fetch(apiUrl(url), requestOptions)
  await handleBlob(
    response,
    (blob, contentType, fileName) => {
      storeFile(blob, contentType, fileName)
      onSuccess(response)
    },
    onFailure
  )
}

const handleBlob = async (response, onSuccess, onFailure) => {
  if (response.status === 200) {
    const contentType = response.headers.get('Content-Type')
    const contentDisposition = response.headers.get('Content-Disposition')

    const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : 'download'
    const blob = await response.blob()

    onSuccess(blob, contentType, fileName)
  } else {
    defaultOnFailure(response, onFailure, false)
  }
}
