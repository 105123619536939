import React, { useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import Draggable from 'react-draggable'

export default function DraggableModal ({ title, description, buttonText, linkText }) {
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  })

  const draggleRef = useRef(null)
  const showModal = () => { setOpen(true) }
  const handleOk = () => { setOpen(false) }
  const handleCancel = () => { setOpen(false) }
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement
    const targetRect = draggleRef.current?.getBoundingClientRect()
    if (!targetRect) {
      return
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    })
  }
  return (
    <>
      {linkText &&
        <span style={{ cursor: 'pointer', display: 'inline-grid', alignContent: 'center' }} onClick={showModal}>
          {linkText}
        </span>
      }
      {buttonText &&
        < Button className="icon-btn" style={{ margin: '0 2em 1em 0' }} size="large" onClick={showModal}>
          {buttonText}
        </Button >
      }
      <Modal
        title={
          <div style={{ width: '100%', cursor: 'move', backgroundColor: 'none' }}
            onMouseOver={() => { if (disabled) { setDisabled(false) } }}
            onMouseOut={() => { setDisabled(true) }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => { }}
            onBlur={() => { }}
          // end
          >
            {title}
          </div>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        mask={false}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {description}
      </Modal >
    </>
  )
}
