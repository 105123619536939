import React, { useContext } from 'react'
import { Button, Form, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { SaveOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import AccidentReportContext from '../AccidentReportContext'
import UserContext from '../../UserContext'
import TaskCreate from '../../Task/TaskCreate'
import TaskGenerator from '../../Task/TaskGenerator'
import TaskListModal from '../../Task/TaskListModal'

export default function Chart ({ ishikawa, report, tasks }) {
  const [form] = Form.useForm()
  const { saveIshikawa } = useContext(AccidentReportContext)
  const { user } = useContext(UserContext)
  const causes = {
    manpower: ishikawa.manpower,
    machine: ishikawa.machine,
    material: ishikawa.material,
    method: ishikawa.method,
    measurement: ishikawa.measurement
  }
  form.setFieldsValue(ishikawa)

  const getTopCauses = (causes) => {
    const categories = Object.keys(causes)
    const middle = parseInt(categories.length / 2)
    const topArray = categories.slice(0, middle)

    const topCauses = topArray.map((category) => (
      <div key={`top_causes_${category}`} className="causeContent">
        <div className="cause top orange_ orangeBorder">
          {t(`Ishikawa.attributes.${category}`)}
        </div>
        <div className="causeAndLine">
          {getRootCauses(category)}
          <div className="diagonalLine orangeTopBottom" />
        </div>
      </div>
    ))

    return (<div className="causesGroup">{topCauses}</div>)
  }

  const getRootCauses = (category) => {
    const selectedTasks = tasks.filter((task) => task.category === category)
    return (
      <div className="rootCauses">
        <Row>
          <Form.Item name={category}>
            <TextArea rows={6} style={{ width: '300px' }} />
          </Form.Item>
        </Row>
        <Row>
          <TaskListModal tasks={selectedTasks} />
          {(selectedTasks.length === 0) && <TaskGenerator category={category} report={report} />}
          <TaskCreate report={report} smallTask category={category} />
        </Row>
      </div>

    )
  }

  const getBottomCauses = (causes) => {
    const categories = Object.keys(causes)
    const middle = parseInt(categories.length / 2)
    const bottomArray = categories.slice(middle)
    const bottomCauses = bottomArray.map((category) => (
      <div key={`bottom_causes_${category}`} className="causeContent">
        <div className="causeAndLine">
          {getRootCauses(category)}
          <div className="diagonalLine orangeBottomTop" />
        </div>
        <div className="cause bottom orange_ orangeBorder">
          {t(`Ishikawa.attributes.${category}`)}
        </div>
      </div>
    ))

    return (<div className="causesGroup">{bottomCauses}</div>)
  }

  const getCauses = () => (
    <div className="causes">
      {getTopCauses(causes)}
      <div className="lineEffect orangeBorder" />
      {getBottomCauses(causes)}
    </div>
  )

  const getEffect = () => (
    <div className="effect left orange_ orangeBorder">
      <Form.Item name="title" className="effectValues">
        <TextArea rows={2} style={{ width: '300px' }} />
      </Form.Item>
    </div>
  )

  const disabled = !user.is_paid && report.free_data

  return (
    <div className="cols-xs-4">
      <Form id="ishikawaForm" layout="vertical" form={form}>
        <div className="fishboneChart">
          {getCauses()}
          {getEffect()}
        </div>
        <Button
          className="icon-btn"
          icon={<SaveOutlined />}
          style={{ margin: '1em 2em 0 0' }}
          onClick={() => saveIshikawa(ishikawa, form.getFieldsValue())}
          size="large"
          disabled={disabled}
        >
          {t('Actions.update')}
        </Button>
      </Form>
    </div>
  )
}
