import React from 'react'
import { t } from 'i18next'
import { List } from 'antd'
import DraggableModal from '../common/DraggableModal'

export default function TaskListModal ({ tasks }) {
  const linkText = t('Ishikawa.attributes.associatedTasks', { count: tasks.length }
  )

  const description = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={tasks}
        renderItem={(task) => (
          <List.Item>
            <List.Item.Meta
              title={task.title}
              description={task.description}
            />
          </List.Item>
        )}
      />
    )
  }

  return (
    <DraggableModal title={t('TaskListModal.title')} description={description()}
      linkText={linkText} />
  )
}
