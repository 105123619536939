import React, { useContext, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, Col, Menu, Row, Tour } from 'antd'
import { Color } from '../common/Color'
import { logOut, post } from '../common/api'
import { SettingOutlined, LogoutOutlined, LoginOutlined } from '@ant-design/icons'
import UserContext from '../UserContext'
import Step from './Step'

const homepageItem = {
  label: (
    <Link to="/" style={{ width: '200px' }}>
      <div>
        <img src="/assets/logo-ws-analytics-H.svg" alt="Logo ws analytics" style={{ padding: '15px 0', verticalAlign: 'middle' }} />
      </div>
    </Link>
  ),
  key: ''
}

const notConnectedItems = (open, dashboardRef, reportRef, taskRef, roadMapRef, subscriptionRef, signUpRef) => {
  const style = open ? { display: 'initial' } : { display: 'none' }
  const items = [
    homepageItem,
    {
      label: (<Link ref={dashboardRef} style={style}><div style={{ paddingTop: '8px' }}>Dashboard</div></Link>),
      key: 'dashboard'
    },
    {
      label: (<Link ref={reportRef} style={style}><div style={{ paddingTop: '8px' }}>Événements</div></Link>),
      key: 'reports'
    },
    {
      label: (<Link ref={taskRef} style={style}><div style={{ paddingTop: '8px' }}>Actions</div></Link>),
      key: 'actions'
    },
    {
      label: (<Link ref={roadMapRef} style={style}><div style={{ paddingTop: '8px' }}>Roadmap</div></Link>),
      key: 'calendrier'
    },
    {
      label: (<Link ref={subscriptionRef} style={style}><div style={{ paddingTop: '8px' }}>Abonnement</div></Link>),
      key: 'subscription'
    },
    {
      label: (<Link ref={signUpRef} size="large" onClick={() => (window.location.href = '/users/sign_up')} id="sign_up" type='link' icon={<LoginOutlined />}>
        <div style={{ paddingTop: '8px' }}>Créez un compte</div>
      </Link>),
      key: 'sign_up'
    },
    {
      label: (<Link size="large" onClick={() => (window.location.href = '/users/sign_in')} id="sign_up" type='link' icon={<LoginOutlined />}>
        <div style={{ paddingTop: '8px' }}>Connectez-vous</div>
      </Link>),
      key: 'sign_in'
    }]
  return items
}

const connectedItems = (user) => {
  const subscription = () => {
    post('/payments/create', {}, (response) => {
      window.location.href = response.stripe_url
    })
  }

  const adminItem = {
    label: (<Link to='/admin/users'><div style={{ paddingTop: '8px' }}>Utilisateurs</div></Link>),
    key: 'users'
  }
  const items = [
    homepageItem,
    {
      label: (<Link to='/dashboard'><div style={{ paddingTop: '8px' }}>Dashboard</div></Link>),
      key: 'dashboard'
    },
    {
      label: (<Link to='/reports'><div style={{ paddingTop: '8px' }}>Événements</div></Link>),
      key: 'reports'
    },
    {
      label: (<Link to={'/actions'}><div style={{ paddingTop: '8px' }}>Actions</div></Link>),
      key: 'actions'
    },
    {
      label: (<Link to={'/calendar'}><div style={{ paddingTop: '8px' }}>Roadmap</div></Link>),
      key: 'calendrier'
    }]
  if (!user.is_paid) {
    items.push({
      label: (<Link onClick={subscription}><div style={{ paddingTop: '8px' }}>Abonnement</div></Link>),
      key: 'subscription'
    })
  }
  if (user.admin) {
    items.push(adminItem)
  }
  items.push(
    {
      label: (<Button size="large" type='link' href={'/profile'} icon={<SettingOutlined />} />),
      key: 'profile'
    })
  items.push(
    {
      label: (<Button size="large" onClick={logOut} id="logout" type='link' icon={<LogoutOutlined />} />),
      key: 'logout'
    }
  )
  return items
}

export default function DesktopNavigation () {
  const location = useLocation()
  const [current, setCurrent] = useState(location.pathname.slice(1).split('/')[1] ?? '')
  const { user } = useContext(UserContext)
  const dashboardRef = useRef(null)
  const reportRef = useRef(null)
  const taskRef = useRef(null)
  const roadMapRef = useRef(null)
  const subscriptionRef = useRef(null)
  const signUpRef = useRef(null)
  const [open, setOpen] = useState(false)

  let items = [homepageItem]
  items = (user?.id) ? connectedItems(user) : notConnectedItems(open, dashboardRef, reportRef, taskRef, roadMapRef, subscriptionRef, signUpRef)
  const connectedClass = (user?.id) ? 'connected' : 'not-connected'

  const steps = [
    {
      title: 'Dashboard',
      description: 'Visualisez les indicateurs clés. Vous avez 2 exemples qui ont été créés',
      cover: (
        <img
          alt="Visualisation des indicateurs"
          src="/assets/dashboard.png"
        />
      ),
      target: () => dashboardRef.current
    },
    {
      title: 'Événements',
      description: 'Visualisez vos événements. Créez 10 événements en mode gratuit',
      cover: (
        <img
          alt="Liste des évènements"
          src="/assets/reports.png"
        />
      ),
      target: () => reportRef.current
    },
    {
      title: 'Création d\'événement',
      description: 'Rédigez librement vos événements.',
      cover: (
        <img
          alt="Création d'événement"
          src="/assets/report_creation.png"
        />
      ),
      target: () => reportRef.current
    },
    {
      title: 'Ishikawa',
      description: 'Générez grace à l\'IA WS un diagramme d\'Ishikawa et les actions associées aux différentes causes. Le résultat est modifiable.',
      cover: (
        <img
          alt="Diagramme d'Ishikawa"
          src="/assets/ishikawa.png"
        />
      ),
      target: () => reportRef.current
    },
    {
      title: 'Actions',
      description: 'Visualisez l\'avancement de vos actions par évènement ou globalement',
      cover: (
        <img
          alt="Liste des actions"
          src="/assets/tasks.png"
        />
      ),
      target: () => taskRef.current
    },
    {
      title: 'RoadMap',
      description: 'Visualisez la roadmap sur 12 mois glissant',
      cover: (
        <img
          alt="Roadmap"
          src="/assets/roadmap.png"
        />
      ),
      target: () => roadMapRef.current
    },
    {
      title: 'Abonnez-vous',
      description: 'Abonnez-vous afin de suivre l\'ensemble de vos évènements et bénéficiez des exports',
      target: () => subscriptionRef.current
    },
    {
      title: 'Connectez-vous',
      description: 'Créez votre compte gratuitement pour tester l\'application',
      target: () => signUpRef.current
    }
  ]

  return (
    <>
      <Menu className={connectedClass} style={{ display: 'flex', fontSize: '18px', borderRadius: '16px', backgroundColor: Color.white, color: Color.dark_blue }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
        mode='horizontal'
        items={items} />
      {connectedClass === 'not-connected' &&
        <>
          <Step />
          <Row>
            <Col style={{ margin: 'auto' }}>
              <Button style={{ marginTop: '2em', width: '400px' }} type="primary" className="icon-btn tour-btn" onClick={() => setOpen(true)}>
                Présentation de l&lsquo;application
              </Button>
              <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
            </Col>
          </Row>
        </>
      }
    </>)
}
