import React from 'react'
import { t } from 'i18next'
import DraggableModal from '../common/DraggableModal'

export default function AccidentReportModal ({ report }) {
  return (
    <DraggableModal title={report.title} description={report.description}
      buttonText={t('AccidentReport.modal.buttonText')} />
  )
}
