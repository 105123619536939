import { Spin } from 'antd'
import React, { createContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { get } from './common/api'
import { formDate } from './common/date'

const AdminContext = createContext()
export default AdminContext

export function AdminContextProvider ({ children }) {
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [users, setUsers] = useState([])
  const [lastMessage, setLastMessage] = useState([])

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(
      '/admin/users', {},
      (successResponse) => {
        setUsers(successResponse.users)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const getLastMessage = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(
      '/admin/users/last_message', {},
      (successResponse) => {
        setLastMessage(successResponse.users.map(formatUser))
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const formatMessage = (message) => {
    if (message) {
      return {
        ...message,
        created_at: formDate(message.created_at)
      }
    } else { return {} }
  }

  const formatUser = (user) => {
    return {
      ...user,
      key: user.id,
      last_message: formatMessage(user.last_message)
    }
  }

  const values = {
    getUsers,
    users,
    lastMessage,
    getLastMessage
  }

  return (
    <AdminContext.Provider value={values}>
      <Spin spinning={spinData.loading} tip={spinData.tip} size="large">
        {children || <Outlet />}
      </Spin>
    </AdminContext.Provider>
  )
}
