export const COLORS = {
  manpower: 'volcano',
  machine: 'orange',
  material: 'gold',
  measurement: 'lime',
  method: 'blue'
}

export const CATEGORIES = ['manpower', 'machine', 'material', 'measurement', 'method']

export const MAX_REPORTS_FOR_FREE_USER = 10

export const USE_CHAT = true
