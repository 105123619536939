import React, { useContext } from 'react'
import { t } from 'i18next'
import UserContext from '../UserContext'
import Stripe from './Stripe'
import { Card, Col, Row } from 'antd'

export default function Profile () {
  const { user } = useContext(UserContext)

  return (
    <Row style={{ padding: '50px 15px' }}>

      <Col span={6} ></Col>
      <Col span={12}>

        <Card
          style={{ height: '100%', textAlign: 'center', alignItems: 'center', margin: '0 10px 10px' }}
          title="Votre compte"
        >
          <Row style={{ marginBottom: '2em', textAlign: 'left' }}>
            <Col span={2}></Col>
            <Col span={10} style={{ fontWeight: 'bold' }}>{t('User.attributes.email')} :</Col>
            <Col span={6}>{user.email}</Col>
          </Row>
          {user.is_paid && <Stripe />}
          {!user.is_paid &&
            <Row style={{ marginBottom: '2em', textAlign: 'left' }}>
              <Col span={2}></Col>
              <Col span={22} style={{ fontWeight: 'bold' }}>{t('User.account.free')}</Col>
            </Row>
          }
        </Card>
      </Col>
    </Row>
  )
}
