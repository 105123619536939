import { Spin } from 'antd'
import React, { createContext, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { deleteRequest, get, post, put } from '../common/api'
import { formDate } from '../common/date'
import { stringNormalize } from '../common/string'
import { t } from 'i18next'

const AccidentReportContext = createContext()
export default AccidentReportContext

export function AccidentReportContextProvider ({ children }) {
  const [spinData, setSpinData] = useState({ loading: false, tip: '' })
  const [ishikawa, setIshikawa] = useState(null)
  const [reports, setReports] = useState([])
  const [tasks, setTasks] = useState([])
  const [report, setReport] = useState(null)
  const [calendar, setCalendar] = useState({})
  const navigate = useNavigate()

  const createOrUpdateAccidentReport = async (values, id = null) => {
    const url = id ? `/accident_reports/${id}` : '/accident_reports'
    const method = id ? put : post
    setSpinData({ ...spinData, loading: true })
    await method(
      url,
      values,
      (successResponse) => {
        if (id) {
          const index = reports.findIndex(e => e.id === successResponse.accident_report.id)
          const currentReport = reportFormat(successResponse.accident_report)
          report[index] = currentReport
          setReports([...reports])
          setReport(currentReport)
        } else {
          setReports([...reports, reportFormat(successResponse.accident_report)])
          navigate(`/reports/${successResponse.accident_report.id}`)
        }
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const fillIshikawa = async (id) => {
    const url = `/accident_reports/${id}/ishikawa/generate`
    setSpinData({ ...spinData, loading: true })
    await post(
      url,
      { ia_generation: true },
      (_successResponse) => {
        setReport({ ...report, ishikawa_generation: 'in_progress' })
        setSpinData({ loading: true, tip: 'Génération en cours' })
      }
    )
  }

  const progress = (report) => {
    if (!report.ishikawa.defined) {
      return 'empty_ishikawa'
    } else if (report.tasks_count === 0) {
      return 'ishikawa_defined'
    } else {
      if (report.tasks_progress === 0) {
        return 'tasks_defined'
      } else if (report.tasks_progress < 100) {
        return 'tasks_in_progress'
      } else if (report.tasks_progress === 100) {
        return 'tasks_done'
      }
    }
  }

  const reportFormat = (report) => {
    return {
      ...report,
      occurrence_date: formDate(report.occurrence_date),
      progress: progress(report)
    }
  }

  const taskFormat = (task, AccidentReportId) => {
    const currentReport = reports.find(report => report.id === AccidentReportId)
    const searchData = [currentReport?.title, task.title, task.response, t(`Task.category.${task.category}`), task.leader]
    return {
      ...task,
      accident_report_id: AccidentReportId,
      created_at: formDate(task.created_at),
      due_date: formDate(task.due_date),
      accident_report_title: currentReport ? currentReport.title : '',
      search_data: searchData.map((e) => (stringNormalize(e))).join(' '),
      filter_date: formDate(task.accident_report?.occurrence_date || task.created_at)
    }
  }
  const saveIshikawa = async (ishikawa, values) => {
    const url = `/accident_reports/${ishikawa.accident_report_id}/ishikawa`
    setSpinData({ ...spinData, loading: true })
    await put(
      url,
      values,
      (successResponse) => {
        setIshikawa(successResponse.ishikawa)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const getAccidentReports = async () => {
    setSpinData({ ...spinData, loading: true })
    await get(
      '/accident_reports', {},
      (successResponse) => {
        setReports(successResponse.accident_reports.map(reportFormat))
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const getAccidentReport = async (id) => {
    setSpinData({ ...spinData, loading: true })
    await get(
      `/accident_reports/${id}`, {},
      (successResponse) => {
        const report = reportFormat(successResponse.accident_report)
        const tasks = successResponse.accident_report.tasks.map((elt) => (taskFormat(elt, id)))
        setReport({
          ...report,
          tasks
        })
        setTasks(tasks)
        setIshikawa(report.ishikawa)
      }
    )
    setSpinData({ loading: false, tip: '' })
  }

  const createTask = async (params, accidentReportId) => {
    const onSuccess = (res) => {
      const task = taskFormat(res.task, accidentReportId)
      if (report) { setReport({ ...report, tasks: [...report.tasks, task] }) }
      setTasks([...tasks, task])
    }
    setSpinData({ ...spinData, loading: true })
    await post('/tasks', {
      ...params,
      accident_report_id: accidentReportId
    }, onSuccess)
    setSpinData({ loading: false, tip: '' })
  }

  const updateCalendar = (currentTask) => {
    if (calendar[currentTask.category] && currentTask.selected) {
      const index = calendar[currentTask.category].findIndex(e => e.id === currentTask.id)
      if (index !== -1) {
        calendar[currentTask.category][index] = currentTask
      } else {
        calendar[currentTask.category].push(currentTask)
      }
    }
  }
  const updateTask = async (params, accidentReportId) => {
    const onSuccess = (res) => {
      const currentTask = taskFormat(res.task, res.task.accident_report_id)
      const index = tasks.findIndex(e => e.id === res.task.id)
      tasks[index] = currentTask
      updateCalendar(currentTask)
      setTasks([...tasks])
      if (accidentReportId) {
        const reportIndex = report.tasks.findIndex(e => e.id === res.task.id)
        report.tasks[reportIndex] = currentTask
        setReport({ ...report, tasks: [...report.tasks] })
      }
    }
    setSpinData({ ...spinData, loading: true })
    await put(`/tasks/${params.id}`, {
      ...params,
      accident_report_id: accidentReportId
    }, onSuccess)
    setSpinData({ loading: false, tip: '' })
  }
  const generateTasks = async (id) => {
    setSpinData({ ...spinData, loading: true })
    await post(`/accident_reports/${id}/tasks/generate`,
      {},
      (_successResponse) => {
        setReport({ ...report, tasks_generation: 'in_progress' })
        setSpinData({ loading: true, tip: 'Génération en cours' })
      }
    )
  }

  const validateTask = async (task, accidentReportId) => {
    updateTask({ ...task, progress: 100 }, accidentReportId)
  }

  const getTasks = async () => {
    setSpinData({ ...spinData, loading: true })
    await get('/tasks', {},
      (successResponse) => {
        const newTasks = successResponse.tasks.map((task) => (taskFormat(task, task.accident_report_id)))
        setTasks(newTasks)
      })
    setSpinData({ loading: false, tip: '' })
  }

  const deleteTask = async (id) => {
    setSpinData({ ...spinData, loading: true })
    await deleteRequest(`/tasks/${id}`, {},
      (_successResponse) => {
        const index = tasks.findIndex((task) => (task.id === id))
        if (index !== -1) {
          tasks.splice(index, 1)
        }
        setTasks([...tasks])
        setReport({ ...report, tasks })
      })
    setSpinData({ loading: false, tip: '' })
  }

  const deleteAccidentReport = async (id) => {
    setSpinData({ ...spinData, loading: true })
    await deleteRequest(`/accident_reports/${id}`, {},
      (_successResponse) => {
        const index = reports.findIndex((report) => (report.id === id))
        if (index !== -1) {
          reports.splice(index, 1)
        }
        setReports([...reports])
      })
    setSpinData({ loading: false, tip: '' })
  }

  const getCalendar = async () => {
    setSpinData({ ...spinData, loading: true })
    await get('/tasks/calendar', {},
      (successResponse) => {
        setCalendar(successResponse.tasks)
      })
    setSpinData({ loading: false, tip: '' })
  }

  const values = {
    ishikawa,
    setIshikawa,
    fillIshikawa,
    saveIshikawa,
    getAccidentReports,
    getAccidentReport,
    createOrUpdateAccidentReport,
    createTask,
    updateTask,
    generateTasks,
    validateTask,
    reports,
    setTasks,
    setReport,
    getTasks,
    tasks,
    report,
    getCalendar,
    calendar,
    setSpinData,
    deleteTask,
    deleteAccidentReport
  }

  return (
    <AccidentReportContext.Provider value={values}>
      <Spin spinning={spinData.loading} tip={spinData.tip} size="large">
        {children || <Outlet />}
      </Spin>
    </AccidentReportContext.Provider>
  )
}
