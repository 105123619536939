import { BulbOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import React, { useContext } from 'react'
import { Button, Popover } from 'antd'
import AccidentReportContext from '../AccidentReport/AccidentReportContext'

export default function TaskGenerator ({ report, category }) {
  const { generateTasks } = useContext(AccidentReportContext)

  const generate = async () => {
    await generateTasks(report.id, category)
  }

  return (
    <Popover placement="topLeft" content={t('Task.generate_by_IA')}>
      <Button type="link" className="icon-btn" icon={<BulbOutlined />} onClick={generate} />
    </Popover>
  )
}
