import React, { useContext, useEffect } from 'react'
import { t } from 'i18next'
import UserContext from '../UserContext'
import { Button, Col, Popconfirm, Row } from 'antd'
import { displayDate, unixTimeToDate } from '../common/date'

export default function Stripe () {
  const { getStripeInfos, subscriptionInfos, cancelSubscription } = useContext(UserContext)

  useEffect(() => {
    getStripeInfos()
  }, [])

  const cancel = () => {
    cancelSubscription()
  }

  return (
    <>
      {subscriptionInfos && subscriptionInfos.created &&
        < >
          <Row style={{ marginBottom: '2em', textAlign: 'left' }} >
            <Col span={2}></Col>
            <Col span={22} style={{ fontWeight: 'bold' }}>{t('User.account.title')}</Col>
          </Row>
          <Row style={{ marginBottom: '2em', textAlign: 'left' }} >
            <Col span={2}></Col>
            <Col span={10} style={{ fontWeight: 'bold' }}>{t('User.account.created_at')} :</Col>
            <Col span={12}>{displayDate(unixTimeToDate(subscriptionInfos.created))}</Col>
          </Row>
          <Row style={{ marginBottom: '2em', textAlign: 'left' }} >
            <Col span={2}></Col>
            <Col span={10} style={{ fontWeight: 'bold' }}>{t('User.account.current_period_label')} :</Col>
            <Col span={12}>{t('User.account.current_period',
              {
                startDate: displayDate(unixTimeToDate(subscriptionInfos.current_period_start)),
                endDate: displayDate(unixTimeToDate(subscriptionInfos.current_period_end))
              })}</Col>
          </Row >
          <Row style={{ marginBottom: '2em', textAlign: 'left' }} >
            <Popconfirm
              title={t('User.subscription.title')}
              description={t('User.subscription.description')}
              onConfirm={cancel}
              okText={t('Actions.yes')}
              cancelText={t('Actions.no')}
            >
              <Button
                className="icon-btn"
                style={{ margin: '0 2em 1em 0' }}
                size="large"
                danger>
                {t('User.subscription.cancel')}
              </Button>
            </Popconfirm>
          </Row>
        </>
      }
    </>
  )
}
