import React from 'react'
import { Card, Col, Row } from 'antd'
import { useMediaQuery } from 'react-responsive'
import SecurityMessage from './SecurityMessage'

export default function HomePage () {
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

  const largeCol = isMobile ? 24 : 8
  const smallCol = isMobile ? 0 : 2

  return (
    <div className="home">
      <div className="content">
        <Row style={{ paddingTop: '15px' }}>
          <Col span={smallCol} ></Col>
          <Col span={2 * largeCol + smallCol} >
            <SecurityMessage />
          </Col>
        </Row>
        <Row style={{ paddingTop: '15px' }}>
          <Col span={smallCol} ></Col>
          <Col span={largeCol} style={{ textTransform: 'uppercase', fontWeight: 'bold', marginBottom: '20px' }}>

            <Card
              style={{ height: '100%', textAlign: 'center', alignItems: 'center' }}
              title={<img className="card-logo" src="/assets/logo-ws-analytics-H.svg" alt="Logo ws analytics" />}
            >
              <div className='title' style={{ color: '#D3A800' }}>Analyse Work & Safety</div>
              <div style={{ textTransform: 'none', fontWeight: 'normal' }}>
                L’IA « WS » permet de mettre à la portée de tous les outils et méthodes professionnels reconnus pour l’analyse des données
                et évènements d’accident ou quasi-accident.
                La solution WS-ANALYTICS créée par Ajonc utilise l’IA « WS » pour lire les contenus des rapports d’incidents
                afin d’effectuer automatiquement une analyse des causes et proposer des actions à mettre en œuvre évitant ainsi une récidive.
                La solution WS-ANALYTICS comprend également un outil de pilotage pour le suivi des mesures correctives et l’amélioration de la prévention.
              </div>
              <div style={{ fontWeight: 'bold' }}>
                La solution WS-ANALYTICS permet d’analyser et monitorer les accidents au travail.
              </div>
            </Card>
          </Col>
          <Col span={smallCol} ></Col>
          <Col span={largeCol} style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            <Card
              style={{ height: '100%', textAlign: 'center', alignItems: 'center' }}
              title={<img className='card-logo' src="/assets/logo-ws-predictive-H.svg" alt="Logo ws predictive" />}
            >
              <div className='title' style={{ color: '#D3A800' }}>AI to Work in Safety</div>
              <div style={{ textTransform: 'none', fontWeight: 'normal' }}>
                Les données issues de WS-ANALYTICS sont ensuite utilisées pour créer une Intelligence Artificielle (WS-PREDICTIVE)
                permettant d&apos;anticiper un incident et de réduire les risques au travail.
                Un nouveau programme de R&D est ouvert depuis juin 2023 pour faire évoluer le modèle de données.
              </div>
              <div style={{ fontWeight: 'bold' }}>
                La solution WS-PREDICTIVE permet de réduire et anticiper les accidents au travail et les maladies professionnelles
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
