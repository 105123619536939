import dayjs from 'dayjs'

export const displayDate = (date) => {
  if (date) {
    return date.format('DD/MM/YYYY')
  }
}

export const displayDateAndHour = (date) => {
  if (date) {
    return date.format('DD/MM/YYYY - HH:mm')
  }
}

export const formDate = (date) => {
  if (date && date !== 'None') return dayjs(date)
  return ''
}

export const unixTimeToDate = (unixTime) => {
  return dayjs.unix(unixTime)
}
